<template>
    <div class="content">
        <div class="block" style="margin-top: 0">
            <div class="quota-content">
                <div class="quota-item">
                    <p>今日浏览量(PV)</p>
                    <p class="num">{{ today_num }}</p>
                </div>
                <div class="quota-item">
                    <p>今日IP数</p>
                    <p class="num">{{ today_ip_num }}</p>
                </div>
                <div class="quota-item">
                    <p>昨日浏览量</p>
                    <p class="num">{{ yesterday_num }}</p>
                </div>
                <div class="quota-item">
                    <p>昨日IP数</p>
                    <p class="num">{{ yesterday_ip_num }}</p>
                </div>
                <div class="quota-item">
                    <p>近7天浏览量</p>
                    <p class="num">{{ seven_day_num }}</p>
                </div>
                <div class="quota-item">
                    <p>一年总浏览量</p>
                    <p class="num">{{ history_num }}</p>
                </div>
            </div>
        </div>
        <lineChart></lineChart>
        <map-chart></map-chart>
        <userAction></userAction>
    </div>
</template>

<script>
import axios from "axios";
import lineChart from "./son/line-chart";
import mapChart from "./son/map-chart";
import userAction from "./son/user-action";

export default {
    components: {
        lineChart,
        mapChart,
        userAction,
    },
    data() {
        return {
            today_num: 0, // 今日浏览量
            today_ip_num: 0, // 今日IP数
            yesterday_num: 0, // 昨日日浏览量
            yesterday_ip_num: 0, // 昨日ip数
            seven_day_num: 0, // 近7天浏览量
            history_num: 0, //一年内浏览量
        };
    },
    async created() {
        let { data } = await axios.get("/api/website/all");
        this.today_num = data.today_num;
        this.today_ip_num = data.today_ip_num;
        this.yesterday_num = data.yesterday_num;
        this.yesterday_ip_num = data.yesterday_ip_num;
        this.seven_day_num = data.seven_day_num;
        this.history_num = data.history_num;
    },

    methods: {},
};
</script>

<style scoped lang="less">
.content {
    margin-top: 20px;
}
.quota-content {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    p {
        white-space: nowrap;
    }
}

.quota-item {
    flex: 1;
    text-align: center;
}

.quota-item .num {
    font-size: 1.2rem;
    margin-bottom: 0;
}

.block {
    background-color: #ffffff;
    margin-top: 1rem;
    padding: 1.5rem;
    border-radius: 2px;
}

.block-name {
    padding: 0.5rem;
    border-bottom: 1px solid #f0f0f0;
}

.block-nopadding {
    padding: 0 !important;
}

.padding-left-right {
    padding: 1.5rem;
}

.title-part {
    display: flex;
    align-items: end;
}

.module-title {
    font-size: 18px;
    font-weight: 500;
    height: 18px;
    line-height: 18px;
}

.list {
    border: 1px solid #e9e9e9;
    margin-top: 1.5rem;
}

.day-switch {
    color: #999;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    vertical-align: middle;
    margin-left: 1rem;
    cursor: pointer;
}

.total-number {
    flex: 1;
    text-align: right;
    cursor: auto;
    display: block;
}

.item {
    padding: 0 0.5rem;
    border-right: 1px solid #999;
}

.item:first-child {
    padding-left: 0;
}

.item:last-child {
    border-right: none;
}

.item:hover {
    color: rgba(1, 189, 255, 0.75);
}

.active {
    color: #01aaed;
}

.line-chart {
    height: 300px;
    margin-top: 1.5rem;
}

.map-chart {
    height: 400px;
}

.list-head {
    color: #8590a6;
    border-bottom: 1px solid #f0f0f0;
    padding: 8px 0;
}

.list-tr {
    //border-bottom: 1px solid #f0f0f0;
    padding: 8px 0;
    border-bottom: 1px solid #e9e9e9;
}

.list-td {
    flex: 1;
    padding-left: 1rem;
    overflow: hidden;
}

.line-heigh {
    line-height: 1.1rem;
}

.list-td:nth-child(1) {
    flex: 18%;
}

.list-td:nth-child(2) {
    flex: 37%;
}

.list-td:nth-child(3) {
    flex: 13%;
}

.list-td:nth-child(4) {
    flex: 10%;
    border-right: none;
    text-align: center !important;
}

.list-td:nth-child(5) {
    flex: 22%;
    border-right: none;
}

.list-td ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 16px;
}

.list .single {
    border: 1px solid FontColorPagNation;
}

.your-ip {
    padding-top: 0.2rem;
}

.list .align {
    text-align: right;
    padding-right: 1rem;
}
.text-center {
    text-align: center;
}
</style>
