<template>
    <div class="block">
        <div class="title-part">
            <div class="module-title">流量趋势</div>
        </div>
        <div class="line-chart" id="line-chart"></div>
    </div>
</template>

<script>
import axios from "axios";
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");
// import echarts from 'echarts';

export default {
    data() {
        return {
            // 折线图数据
            lineChartOption: {
                tooltip: {
                    trigger: "axis",
                },
                dataZoom: [
                    {
                        type: "inside",
                        filterMode: "filter",
                        start: 90,
                        end: 100,
                    },
                    {
                        type: "slider",
                        filterMode: "filter",
                        start: 90,
                        end: 100,
                    },
                ],
                xAxis: { data: [] },
                yAxis: {},
                legend: {
                    data: ["浏览量", "IP数"],
                    top: "0%",
                },
                series: [
                    {
                        name: "浏览量",
                        type: "line",
                        data: [],
                        itemStyle: {
                            color: "#7781f1",
                            label: { show: true },
                            lineStyle: { color: "#7781f1" },
                        },
                    },
                    {
                        name: "IP数",
                        type: "line",
                        data: [],
                        itemStyle: {
                            color: "#F72C5B",
                            label: { show: true },
                            lineStyle: { color: "#F72C5B" },
                        },
                    },
                ],
            },
            // 折线图对象
            lineChart: "",
            // 指标区域的数据
            quateData: {},
            // 防抖函数
            afn: null,
            listData: [],
        };
    },
    async created() {
        this.listData = await this.getData();
    },
    mounted() {
        Vue.prototype.$echarts = echarts;
        let timer = null;
        let afn = () => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                this.lineChart.resize();
            }, 50);
        };
        window.addEventListener("resize", afn);
        this.afn = afn;
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.afn);
    },
    methods: {
        setLineChart() {
            let that = this;
            if (!that.lineChart) that.lineChart = that.$echarts.init(document.getElementById("line-chart"));
            let arr = this.listData.map((e) => {
                return {
                    time: e.time,
                    num: e.num,
                    ip: e.ip,
                };
            });
            wc(arr.reverse());
            function wc(data) {
                let times = [],
                    nums = [],
                    ips = [];
                // 因处理数据耗时较长，提前渲染折线图
                // that.lineChart.setOption(that.lineChartOption);
                data.forEach((e) => {
                    times.push(e.time);
                    nums.push(e.num);
                    ips.push(e.ip);
                });

                that.lineChartOption.xAxis.data = times; //time
                that.lineChartOption.series[0].data = nums; //浏览量
                that.lineChartOption.series[1].data = ips; //ip

                that.lineChart.setOption(that.lineChartOption);
            }
        },
        async getData() {
            let { data } = await axios.get("/api/website/flowtrend");
            return data;
        },
    },
    watch: {
        listData() {
            this.$nextTick(() => {
                this.setLineChart();
            });
        },
    },
};
</script>

<style scoped lang="less">
@import url("./a.less");
</style>
