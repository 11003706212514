<template>
    <div class="block">
        <div class="title-part">
            <div class="module-title">用户行为</div>
            <div class="day-switch">
                <div :class="day == 1 ? 'item active' : 'item'" @click="updateDay(1)">今天</div>
                <div :class="day == 2 ? 'item active' : 'item'" @click="updateDay(2)">最近2天</div>
                <div :class="day == 3 ? 'item active' : 'item'" @click="updateDay(3)">最近3天</div>
            </div>
            <div class="day-switch total-number give-up">访问总人数：{{ count }}条</div>
        </div>
        <div class="table">
            <el-table :data="tableData" @row-click="rowClick" :row-style="{ cursor: 'pointer' }">
                <el-table-column prop="cookie" label="cookie" width="300"> </el-table-column>
                <el-table-column prop="children" label="行为数">
                    <template slot-scope="{ row }">
                        <span>{{ row.children.length }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="设备">
                    <template slot-scope="{ row }">
                        <span>{{ row.type ? "移动端" : "PC" }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页器 -->
        <vy-pager :count="count" :page-size="pageSize" :page="page" @current-change="change" @size-change="sizeChange"></vy-pager>
        <!-- 弹窗 -->
        <el-dialog title="行为详情" :visible.sync="dialogShow" width="80%" :before-close="bfc">
            <el-table :data="row.children">
                <el-table-column prop="city" label="城市"> </el-table-column>
                <el-table-column prop="ip" label="ip"> </el-table-column>
                <el-table-column prop="visit_page" label="行为" width="300"> </el-table-column>
                <el-table-column prop="visit_time" label="时间"> </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import VyPager from "@/components/customs/VyPager.vue";

export default {
    components: { VyPager },
    data() {
        return {
            count: 0,
            page: 1,
            pageSize: 10,
            day: 1,
            tableData: [],
            dialogShow: false,
            row: {},
        };
    },
    created() {
        this.load();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/website/useract", {
                params: {
                    page: this.page,
                    day: this.day,
                    pageSize: this.pageSize,
                },
            });
            return data;
        },
        async load() {
            let data = await this.getData();
            this.count = data.count;
            this.tableData = data.data;
        },
        // 改变day
        updateDay(num) {
            this.day = num;
            this.load();
        },
        // 行点击
        rowClick(row, column, event) {
            console.log(row);
            this.row = { ...row };
            this.dialogShow = true;
        },
        // 当前页改变时
        change(currentPage) {
            this.page = currentPage;
            this.load();
        },
        sizeChange(currentPageSize) {
            this.pageSize = currentPageSize;
            this.load();
        },
    },
};
</script>

<style scoped lang="less">
@import url("./a.less");
.table {
    margin-top: 1rem;
}
.page_pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    /deep/.el-pagination {
        border-radius: 50px;
        overflow: hidden;
        padding: 0px;
    }
}
</style>
