<template>
    <div class="block" v-loading="loading">
        <div class="title-part">
            <div class="module-title">访客来源</div>
            <div class="day-switch">
                <div :class="mapDateType == '1' ? 'item active' : 'item'" @click="setMap(1)">今天</div>
                <div :class="mapDateType == '14' ? 'item active' : 'item'" @click="setMap(14)">最近14天</div>
                <div :class="mapDateType == '30' ? 'item active' : 'item'" @click="setMap(30)">最近30天</div>
                <div :class="mapDateType == '60' ? 'item active' : 'item'" @click="setMap(60)">最近60天</div>
            </div>
        </div>
        <div class="map-chart" id="map"></div>
    </div>
</template>

<script>
import china from "@/assets/map/china.json";
import citys from "@/assets/map/citys";
import axios from "axios";
import Vue from "vue";

let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/map");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require("echarts/lib/chart/scatter");

export default {
    data() {
        return {
            // 地图参数
            mapDateType: 1,
            mapList: [],
            mapOption: {
                legend: {
                    data: [],
                },
                tooltip: {
                    show: false,
                },
                geo: {
                    map: "china",
                    roam: true, // 一定要关闭拖拽
                    zoom: 1.7,
                    center: [105, 36], // 调整地图位置
                    showLegendSymbol: false, // 存在legend时显示
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: true,
                            color: "#000000",
                        },
                        itemStyle: {
                            areaColor: "#f6f6f6",
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 5,
                            borderWidth: 0,
                        },
                    },
                    itemStyle: {
                        areaColor: "white",
                        borderColor: "#aeaeae",
                        borderWidth: 0.5,
                    },
                },
                series: [
                    {
                        name: "博客访问来源( 今日 )",
                        type: "scatter",
                        data: [],
                        itemStyle: {
                            shadowBlur: 0,
                            shadowColor: "#333",
                            color: "#01aaed",
                            lineStyle: { color: "#01aaed" },
                        },
                        coordinateSystem: "geo",
                        symbolSize: 5,
                        label: {
                            formatter: "{b}",
                            position: "right",
                            show: true,
                            color: "#727272",
                        },
                    },
                ],
            },
            // 地图对象
            map: "",
            // 防抖函数
            afn: null,
            loading: false,
        };
    },
    mounted() {
        Vue.prototype.$echarts = echarts;
        this.setMap(this.mapDateType);
        let timer = null;
        let afn = () => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                this.map.resize();
            }, 50);
        };
        window.addEventListener("resize", afn);
        this.afn = afn;
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.afn);
    },
    methods: {
        setMap(dayNum) {
            let that = this;
            that.loading = true;
            that.mapDateType = dayNum;
            that.mapList = [];
            that.getData(dayNum).then(res => {
                wc(res);
            });
            function wc(data = []) {
                data.forEach(function (item) {
                    let formatLocation;
                    if (typeof item == "string") {
                        formatLocation = item.replace("市", ""); // 数据库存放的城市名称包含'市'字，但是所有城市维度数据中城市名称没有
                        if (citys[formatLocation]) {
                            that.mapList.push({
                                name: formatLocation,
                                value: citys[formatLocation],
                            });
                        }
                    }
                });

                if (!that.map) that.map = that.$echarts.init(document.getElementById("map"));
                that.mapOption.series[0].data = that.mapList;
                that.$echarts.registerMap("china", china);
                that.map.setOption(that.mapOption, true);
                setTimeout(() => {
                    that.loading = false;
                }, 200);
            }
        },
        async getData(day) {
            let { data } = await axios.get("/api/website/city", { params: { day } });
            return data.map(e => e.replace("市", ""));
        },
    },
};
</script>

<style scoped lang="less">
@import url("./a.less");
</style>
