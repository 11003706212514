<template>
    <div v-loading="loading" element-loading-background="#fff">
        <template v-if="$userLevel == 1">
            <ul class="topList">
                <li v-for="(el, index) in topList" :key="index" @click="el.goPath ? $router.push(el.goPath) : ''">
                    <div>
                        <div>
                            <i :class="el.icon"></i>
                        </div>
                        <div>
                            <span>{{ el.num }}</span>
                            <p>{{ el.str }}</p>
                        </div>
                    </div>
                    <p>{{ el.info }}</p>
                    <img :src="el.bgImg" />
                </li>
            </ul>
            <chart></chart>
        </template>
        <template v-else-if="$userLevel == 2">
            <div class="user">
                <img src="https://iv.vu818.com/img/vu818lg.png" />
            </div>
        </template>
    </div>
</template>
<script>
import axios from "axios";
import chart from "./chart.vue";
export default {
    components: { chart },
    name: "vyHomePage",
    data() {
        return {
            topList: [
                {
                    icon: "el-icon-monitor",
                    num: 0,
                    str: "服务器",
                    info: "查看全部",
                    key: "server",
                    goPath: "/home/vyServer_lb",
                    bgImg: "img/36.png",
                },
                {
                    icon: "el-icon-mouse",
                    num: 0,
                    str: "项目开发",
                    info: "查看全部",
                    key: "project_pace",
                    goPath: "/home/vyProject",
                    bgImg: "img/37.png",
                },
                {
                    icon: "el-icon-message",
                    num: 0,
                    str: "顾客之声",
                    info: "查看全部",
                    key: "mail",
                    goPath: "/home/vyMessage_lb",
                    bgImg: "img/38.png",
                },
                {
                    icon: "el-icon-view",
                    num: 0,
                    str: "借贷",
                    info: "查看全部",
                    key: "loan",
                    goPath: "/home/vyLoan",
                    bgImg: "img/39.png",
                },
            ],
            loading: true,
        };
    },
    async created() {
        this.userLevel = this.$userLevel;
        if (this.$userLevel == 1) {
            this.vipInit();
        } else if (this.$userLevel == 2) {
            this.ordinaryInit();
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 1000);
    },
    methods: {
        // 管理员初始化
        async vipInit() {
            let { data } = await axios.get("/api/website/num");
            this.topList.forEach(e => {
                e.num = data[e.key];
            });
        },
        // 普通用初始化
        async ordinaryInit() {
            // this.loading = false;
        },
    },
};
</script>
<style lang="less" scoped>
.topList {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    & > li {
        width: 24%;
        box-sizing: border-box;
        background-color: white;
        border-radius: 20px;
        padding: 20px;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        & > div {
            display: flex;
            & > div:nth-of-type(1) {
                width: 60px;
                height: 60px;
                line-height: 60px;
                margin-right: 20px;
                background-color: #e9e4fd;
                border-radius: 50%;
                text-align: center;
                i {
                    font-size: 20px;
                }
            }
            & > div:nth-of-type(2) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                span {
                    font-size: 20px;
                }
            }
            position: relative;
            z-index: 1;
        }
        & > p {
            padding-top: 20px;
            position: relative;
            z-index: 1;
            color: #ccc;
        }
        & > img {
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
    }
}
.user {
    height: 600px;
    width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
